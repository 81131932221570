import RizkService from '@Services/rizkService';
import ReactLobby from './components/reactLobby';
// import { webSocket } from '@Services/websocket'
import { RGTriggers } from './components/InboxNew/triggers';
import CampaignGame from './components/CampaignGame/index';
import SeasonCampaign from './components/valentinesCampaign';
import { Race } from './components/RaceNew/RaceNew';
import { GameSessionHelper } from '@Utils/gameSessionHelper';
import { showClock, lastLogin } from '@Utils';
import { GameStatsCounter } from '@Utils/gameStatsCounter';
import { loadLiveChat, loadFrameChat, loadHelpCenter } from '@Utils/livechat';
import PassField from './components/passfield';
import { BehaviorSubject } from 'rxjs';
import MenuItemLinks from './components/menuItemLinks';

const rizkApp = require('@Helpers/rizk-app');

var passfield = new PassField();
var menuItemLinks = new MenuItemLinks();

/**
 *
 * @param {{...any}} props
 * @deprecated Backwards Support for FrontendV1
 */
export function init(props = {}) {
  const { entrypoint } = props;
  window.Rizk = window.Rizk || {};
  window.Rizk.isFeatureActive = rizkApp.isFeatureActive;
  window.Rizk.isFeatureOn = rizkApp.isFeatureOn;
  window.loadLiveChat = loadLiveChat;
  window.loadFrameChat = loadFrameChat;
  window.loadHelpCenter = loadHelpCenter;
  window.checkPassword = passfield.checkPassword;
  window.checkPasswordEmail = passfield.checkPasswordEmail;
  window.togglePassword = passfield.togglePassword;
  window.Rizk.lazyObservable = new BehaviorSubject([]);
  window.customElements.define('campaign-game', CampaignGame);
  //window.customElements.define("season-campaign", ValentinesCampaign);

  window.linksActive = menuItemLinks.linksActive;

  window.Rizk.cache = {
    get: rizkApp.rizkCacheGet,
    set: rizkApp.rizkCacheSet,
  };

  if (rizkApp.isFeatureOn('react-lobby', RizkConfig)) {
    loadReactLobby();
  }

  window.Rizk.Race = new Race();
  window.addEventListener('load', function () {
    const locale = RizkConfig.locale || 'en';

    this.window.Rizk = this.window.Rizk || {};
    this.window.Rizk.rizkService = new RizkService(locale);
    this.window.Rizk.gameSessionHelper = new GameSessionHelper();
    this.window.Rizk.rgTriggers = new RGTriggers();
    this.window.Rizk.gameStatsCounters = {};
    this.window.Rizk.GameStatsCounter = GameStatsCounter;
    // this.window.Rizk.Socket = webSocket(window.location.host, RizkConfig)
    // Commented out because of the new react Lobby
    // setTimeout(function(){
    //   window.Rizk.SeasonCampaign = new SeasonCampaign()
    // }, 500);

    menuItemLinks.linksActive();

    if (entrypoint !== 'frameview') {
      showClock(document.getElementById('clock'));
      lastLogin();
    }
  });
}

async function loadReactLobby() {
  const reactLobby = new ReactLobby();
  reactLobby.init();
  await import('@zecure-react-components/lobby/dist/NewLobby');
}
